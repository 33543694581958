// Site
export const SERVICE_NAME = 'Tell Border Force and HMRC you are sailing to or from the UK in a pleasure craft';

// Account
export const USER_NOT_VERIFIED_STATUS_MESSAGE = 'User not verified, please verify registration';

// Contact information
export const SUPPORT_EMAIL = 'spcr@homeoffice.gov.uk';
export const YACHTLINE_NUMBER = '0300 123 2012';
export const BF_CONTACT_NUMBER_PDF_DATE_OF_CHANGE = '2024-06-18';
export const BF_CENTRAL_NUMBER = '+44 (0)300 106 5509';
export const BF_NORTH_NUMBER = '+44 (0)300 104 5941';
export const BF_SOUTH_EAST_NUMBER = '+44 (0)300 073 8219';

export const BF_BRISTOL_NUMBER = '+44 (0)1275 473 757';
export const BF_DEVON_AND_CORNWALL_NUMBER_1 = '+44 (0)300 073 9621';
export const BF_DEVON_AND_CORNWALL_HOURS_1 = '8am to 8pm';
export const BF_DEVON_AND_CORNWALL_NUMBER_2 = '+44 (0)7880 054 844';
export const BF_DEVON_AND_CORNWALL_HOURS_2 = '8pm to 8am';
export const BF_NEWHAVEN_NUMBER = '+44 (0)300 073 8592';
export const BF_POOLE_NUMBER_1 = '+44 (0)1202 634 555';
export const BF_POOLE_HOURS_1 = '8am to 8pm';
export const BF_POOLE_NUMBER_2 = '+44 (0)7880 054 844';
export const BF_POOLE_HOURS_2 = '8pm to 8am';
export const BF_PORTSMOUTH_NUMBER = '+44 (0)7557 205 925';
export const BF_SOUTH_WALES_NUMBER = '+44 (0)300 1064 188';
export const BF_WEST_WALES_NUMBER = '+44 (0)300 1065 322';
export const BF_SOUTHAMPTON_NUMBER = '+44 (0)7880 054 844';
export const UK_BF_CARRIER_SUP_HUB = '0300 369 0610';
export const INT_BF_CSH_PHONE = '+44 204 619 6020';

// Forms
export const AUTOCOMPLETE_DIALCODE = 'dialCode';
export const SESSION_USE_DEFAULT_LABEL = 'useDefault';

// Pleasure Craft form
export const ADD_A_PLEASURE_CRAFT_TEXT = 'Add a pleasure craft';
export const MOTORBOAT_TEXT = 'Motorboat';
export const SAILING_BOAT_TEXT = 'Sailing boat';
export const REGISTRATION_FIELD_NAME = 'registrationCountryName';
export const MAX_CHAR_REGISTRATION = 15;
export const MAX_CHAR_SHIP_NAME = 35;

// Interstitial page
export const INFO_COOKIE_NAME = 'INFO_VERSION';
export const INFO_VERSION = '2'; // update this to force user to see interstitial on next sign in
export const INFO_COOKIE_AGE = 365 * 24 * 60 * 60; // 365 times 24 times 60 times 60

// People
export const PERSON_TYPE_EMPLOYEE = 'Crew';
export const PERSON_TYPE_PASSENGER = 'Passenger';

// Reports
export const PAGE_SIZE_PARAM = 'per_page=10';
export const CONTINUE_ONLY = 'continueOnly';
export const EDITING_VOYAGE_TYPE = 'edit';
export const NEW_VOYAGE_TYPE = 'new';
export const VOYAGE_PAGE_TYPE_SELECTOR = 'selectorPage';
export const SUBMITTED_VOYAGE_STATUS = 'Submitted';

// Status tags
export const STATUS_TAG_CANNOT_START = 'cannotStartYet';
export const STATUS_TAG_COMPLETED = 'completed';
export const STATUS_TAG_INCOMPLETE = 'incomplete';
export const STATUS_TAG_IN_PROGRESS = 'inProgress';
export const STATUS_TAG_READY_TO_VIEW = 'readyToView';
export const STATUS_TAG_UPT_NOT_REQUIRED = 'notRequired';

// UPT scenarios
export const UPT_STATUS_DEPARTURE = 'departure';
export const UPT_STATUS_SECTION_ONE_INCOMPLETE = 'sectionOneIncomplete';
export const UPT_STATUS_ERROR_SYSTEM = 'systemError';
export const UPT_STATUS_IN_PROGRESS = 'inProgress';
export const UPT_STATUS_CHECKS_REQUIRED = 'checksRequired';
export const UPT_STATUS_ERROR_DATA = 'dataError';
export const UPT_STATUS_UPT_SEEN = 'uptSeen';
export const UPT_STATUS_RESULTS_READY = 'uptResultsReady';

// Various Actions
export const ACTION_CHECKIN = 'actionCheckin';
export const ACTION_NAVIGATE = 'actionNavigate';
export const LINK_ACTIVE = 'linkActive';
export const LINK_DISABLED = 'linkDisabled';

// Voyage
export const UPT_CHECKS_REQUIRED_NO = 'uptChecksRequiredFalse';
export const UPT_CHECKS_REQUIRED_YES = 'uptChecksRequiredTrue';
export const UPT_STATUS_ERROR_USERDATA = 'errorUserData';
export const UPT_RESPONSE_AUTHORITY_TO_CARRY_GRANTED = 'AUTHORITY TO CARRY GRANTED';
export const UPT_RESPONSE_VALID_PERMISSION_TO_TRAVEL = 'VALID PERMISSION TO TRAVEL';
export const UPT_PERMISSION_TO_TRAVEL_ERROR = 'Sorry, there is a problem while processing permission to travel checks';

export const VOYAGE_STATUSES = {
  CANCELLED: 'Cancelled',
  DRAFT: 'Draft',
  FAILED: 'Failed',
  PRE_CANCELLED: 'PreCancelled',
  PRE_SUBMITTED: 'PreSubmitted',
  SUBMITTED: 'Submitted',
};