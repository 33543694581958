import { lazy, Suspense } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import ActionEntity from './components/ActionEntity';
import LoadingSpinner from './components/LoadingSpinner';
import {
  PEOPLE_URL,
  USER_URL,
  VESSELS_URL,
  VOYAGE_REPORT_URL,
} from './constants/AppApiConstants';
import { SERVICE_NAME, VOYAGE_STATUSES } from './constants/AppConstants';
import ProtectedRoutes from './utils/ProtectedRoutes';
import useUserIsPermitted from './utils/useUserIsPermitted';
// URLs
import {
  ACCESSIBILITY_STATEMENT_URL,
  ACCOUNT_PAGE_URL,
  ACTIVATE_ACCOUNT_URL,
  CREATE_VOYAGE_URL,
  DASHBOARD_URL,
  DELETE_ACCOUNT_URL,
  DELETE_ACCOUNT_SUCCESS_URL,
  EDIT_ACCOUNT_URL,
  HELP_FOOTER_URL,
  HELP_NAV_URL,
  INTERSTITIAL_URL,
  MANAGE_VOYAGE_PLANS,
  MESSAGE_URL,
  ONBOARDING_PEOPLE_URL,
  ONBOARDING_SKIPPER_URL,
  PASSWORD_FORGOT_URL,
  PASSWORD_NEW_URL,
  PLEASURE_CRAFT_ADD_URL,
  PLEASURE_CRAFT_EDIT_URL,
  PLEASURE_CRAFT_PAGE_URL,
  PEOPLE_PAGE_URL,
  PERSON_ADD_URL,
  PERSON_EDIT_URL,
  PRIVACY_AND_COOKIE_URL,
  REGISTER_URL,
  REGISTRATION_CONFIRMATION_URL,
  RESEND_EMAIL_USER_NOT_VERIFIED,
  SIGN_IN_URL,
  VOYAGE_ARRIVAL_OR_DEPARTURE_UK_URL,
  VOYAGE_ARRIVAL_URL,
  VOYAGE_CHECK_ANSWERS_URL,
  VOYAGE_CUSTOMS_URL,
  VOYAGE_DEPARTURE_URL,
  VOYAGE_EMPLOYEE_CHECK_URL,
  VOYAGE_EMPLOYEE_SELECT_URL,
  VOYAGE_PEOPLE_MANIFEST_URL,
  VOYAGE_SELECT_PEOPLE_URL,
  VOYAGE_SELECT_PLEASURE_CRAFT_URL,
  VOYAGE_SKIPPER_DETAILS_URL,
  VOYAGE_SKIPPER_SELECT_URL,
  VOYAGE_SUBMITTED_URL,
  VOYAGE_TASK_LIST_URL,
  VOYAGE_UPT_RESULTS,
  UPT_HELP_URL,
  ERROR_MESSAGE_URL,
} from './constants/AppUrlConstants';

// Lazy loaded routes (js loads on demand)
// Account
const Account = lazy(() => import('./pages/account/Account'));
const AccountForm = lazy(() => import('./pages/account/AccountForm'));
const DeleteConfirmation = lazy(() => import('./pages/account/DeleteConfirmation'));
// Dashboard/Support
const AccessibilityStatement = lazy(() => import('./pages/regulatory/AccessibilityStatement'));
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const ManageReports = lazy(() => import('./pages/dashboard/ManageReports'));
// Onboarding
const OnboardingPeople = lazy(() => import('./pages/onboarding/OnboardingPeople'));
const OnboardingSkipper = lazy(() => import('./pages/onboarding/OnboardingSkipper'));
// People
const PeoplePage = lazy(() => import('./pages/people/PeoplePage'));
const PersonForm = lazy(() => import('./pages/people/PersonForm'));
// Pleasure craft
const PleasureCraftForm = lazy(() => import('./pages/pleasureCrafts/PleasureCraftForm'));
const PleasureCraftsPage = lazy(() => import('./pages/pleasureCrafts/PleasureCraftsPage'));
// Regulator and help
const ServiceHelp = lazy(() => import('./pages/help/LoggedInHelp'));
const UptHelp = lazy(() => import('./pages/regulatory/Help/UPTHelpContainer'));
// Registration & Sign in
const UserRegisterConfirmation = lazy(() => import('./pages/registerAndSignIn/UserRegisterConfirmation'));
const Interstitial = lazy(() => import('./pages/regulatory/Interstitial'));
// Voyage
const ArrivalForm = lazy(() => import('./pages/voyage/ArrivalForm'));
const CustomsForm = lazy(() => import('./pages/voyage/CustomsForm'));
const CYAContainer = lazy(() => import('./pages/voyage/CheckYourAnswers/CYAContainer'));
const DepartureForm = lazy(() => import('./pages/voyage/DepartureForm'));
const EmployeesCheck = lazy(() => import('./pages/voyage/VoyagePeople/EmployeesCheck'));
const EmployeesSelect = lazy(() => import('./pages/voyage/VoyagePeople/EmployeesSelect'));
const PeopleManifest = lazy(() => import('./pages/voyage/VoyagePeople/PeopleManifest'));
const PeopleSelectorForm = lazy(() => import('./pages/voyage/VoyagePeople/PeopleSelectorForm'));
const PleasureCraftList = lazy(() => import('./pages/voyage/PleasureCraftList'));
const SkipperAdditionalDetailsForm = lazy(() => import('./pages/voyage/SkipperDetails/SkipperPersonDetails'));
const SkipperDetailsForm = lazy(() => import('./pages/voyage/SkipperDetails/SkipperDetailsForm'));
const SkipperSelectForm = lazy(() => import('./pages/voyage/SkipperDetails/SkipperSelectForm'));
const TaskListContainer = lazy(() => import('./pages/voyage/TaskList/TaskListContainer'));
const UptResultsPageContainer = lazy(() => import('./pages/voyage/voyageUpt/UptResultsPageContainer'));
const VoyageStart = lazy(() => import('./pages/voyage/VoyageStart'));
const VoyageSubmitted = lazy(() => import('./pages/voyage/VoyageSubmitted'));
const VoyageType = lazy(() => import('./pages/voyage/VoyageType/VoyageTypeContainer'));
// Other
const GenericMessage = lazy(() => import('./pages/GenericMessage'));
const ErrorMessage = lazy(() => import('./pages/ErrorMessage'));

// v1 pages
const Help = lazy(() => import('./pages/regulatory/Help/Help'));
const PrivacyCookiePolicy = lazy(() => import('./pages/regulatory/PrivacyCookiePolicy'));
const AccountActivation = lazy(() => import('./pages/registerAndSignIn/AccountActivation'));
const SignIn = lazy(() => import('./pages/registerAndSignIn/SignIn'));
const UserRegister = lazy(() => import('./pages/registerAndSignIn/UserRegister'));
const NewPassword = lazy(() => import('./pages/registerAndSignIn/NewPassword'));
const ForgottenPassword = lazy(() => import('./pages/registerAndSignIn/ForgottenPassword'));
const LandingPage = lazy(() => import('./pages/landing/LandingPage'));
const ResendEmailUserNotVerified = lazy(() => import('./pages/registerAndSignIn/ResendEmailUserNotVerified'));

const AppRouter = () => {
  document.title = SERVICE_NAME;
  const isPermittedToView = useUserIsPermitted();

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path={ERROR_MESSAGE_URL} element={<ErrorMessage />} />
        <Route path={MESSAGE_URL} element={<GenericMessage />} />
        {/* Register/Signin */}
        <Route path={PASSWORD_FORGOT_URL} element={<ForgottenPassword />} />
        <Route path={PASSWORD_NEW_URL} element={<NewPassword />} />
        <Route path={REGISTER_URL} element={<UserRegister />} />
        <Route path={REGISTRATION_CONFIRMATION_URL} element={<UserRegisterConfirmation />} />
        <Route path={ACTIVATE_ACCOUNT_URL} element={<AccountActivation />} />
        <Route path={RESEND_EMAIL_USER_NOT_VERIFIED} element={<ResendEmailUserNotVerified />} />
        <Route path={SIGN_IN_URL} element={<SignIn />} />
        {/* Regulatory */}
        <Route path={PRIVACY_AND_COOKIE_URL} element={<PrivacyCookiePolicy />} />
        <Route path={HELP_FOOTER_URL} element={<Help />} />
        <Route path={ACCESSIBILITY_STATEMENT_URL} element={<AccessibilityStatement />} />
        {/* Other */}
        <Route path={DELETE_ACCOUNT_SUCCESS_URL} element={<DeleteConfirmation />} />

        <Route element={<ProtectedRoutes isPermittedToView={isPermittedToView} />}>
          <Route path={MANAGE_VOYAGE_PLANS} element={<ManageReports />} />
          <Route path={DASHBOARD_URL} element={<Dashboard />} />
          {/**
           * The help page is linked to directly from emails sent to users, this URL can not be allowed to change, or
           * if it does change, a permanent redirect needs to be left in place to the new location with appropriate documentation.
           */
           }
          <Route path={HELP_NAV_URL} element={<ServiceHelp />} />
          <Route path={INTERSTITIAL_URL} element={<Interstitial />} />
          <Route path={UPT_HELP_URL} element={<UptHelp />} />
          {/* Account */}
          <Route path={ACCOUNT_PAGE_URL} element={<Account />} />
          <Route path={EDIT_ACCOUNT_URL} element={<AccountForm />} />
          <Route
            path={DELETE_ACCOUNT_URL}
            element={(
              <ActionEntity
                notification={
                  {
                    title: 'Success',
                    heading: 'Account successfully deleted.',
                    entity: 'account',
                    baseURL: USER_URL,
                    redirectURL: ACCOUNT_PAGE_URL,
                    action: 'Delete',
                  }
                }
              />
            )}
          />
          {/* Onboarding */}
          <Route path={ONBOARDING_SKIPPER_URL} element={<OnboardingSkipper />} />
          <Route path={ONBOARDING_PEOPLE_URL} element={<OnboardingPeople />} />
          {/* People */}
          <Route path="/people" element={<PeoplePage />} />
          <Route path={`${PERSON_ADD_URL}/page-1`} element={<PersonForm />} />
          <Route path={`${PERSON_ADD_URL}/page-2`} element={<PersonForm />} />
          <Route path={`${PERSON_EDIT_URL}/page-1`} element={<PersonForm type="edit" />} />
          <Route path={`${PERSON_EDIT_URL}/page-2`} element={<PersonForm type="edit" />} />
          <Route
            path={`${PEOPLE_PAGE_URL}/:entityId/delete`}
            element={(
              <ActionEntity
                notification={{
                  title: 'Success',
                  heading: 'Person successfully deleted.',
                  entity: 'person',
                  baseURL: PEOPLE_URL,
                  redirectURL: PEOPLE_PAGE_URL,
                  action: 'Delete',
                }}
              />
            )}
          />
          {/* Pleasure Crafts */}
          <Route path="/pleasure-crafts" element={<PleasureCraftsPage />} />
          <Route path={`${PLEASURE_CRAFT_ADD_URL}/page-1`} element={<PleasureCraftForm />} />
          <Route path={`${PLEASURE_CRAFT_ADD_URL}/page-2`} element={<PleasureCraftForm />} />
          <Route path={`${`${PLEASURE_CRAFT_EDIT_URL}/page-1`}`} element={<PleasureCraftForm type="edit" />} />
          <Route path={`${`${PLEASURE_CRAFT_EDIT_URL}/page-2`}`} element={<PleasureCraftForm type="edit" />} />
          <Route
            path={`${PLEASURE_CRAFT_PAGE_URL}/:entityId/delete`}
            element={(
              <ActionEntity
                notification={{
                  title: 'Success',
                  heading: 'Pleasure craft successfully deleted.',
                  entity: 'pleasure craft',
                  baseURL: VESSELS_URL,
                  redirectURL: PLEASURE_CRAFT_PAGE_URL,
                  action: 'Delete',
                }}
              />
            )}
          />
          {/* Save a voyage */}
          <Route path={CREATE_VOYAGE_URL} element={<VoyageStart />} />
          <Route path={VOYAGE_ARRIVAL_OR_DEPARTURE_UK_URL} element={<VoyageType />} />
          <Route path={VOYAGE_ARRIVAL_URL} element={<ArrivalForm />} />
          <Route path={VOYAGE_CHECK_ANSWERS_URL} element={<CYAContainer />} />
          <Route path={VOYAGE_CUSTOMS_URL} element={<CustomsForm />} />
          <Route path={VOYAGE_DEPARTURE_URL} element={<DepartureForm />} />
          <Route path={VOYAGE_EMPLOYEE_CHECK_URL} element={<EmployeesCheck />} />
          <Route path={VOYAGE_EMPLOYEE_SELECT_URL} element={<EmployeesSelect />} />
          <Route path={VOYAGE_PEOPLE_MANIFEST_URL} element={<PeopleManifest />} />
          <Route path={VOYAGE_SELECT_PEOPLE_URL} element={<PeopleSelectorForm />} />
          <Route path={VOYAGE_SELECT_PLEASURE_CRAFT_URL} element={<PleasureCraftList />} />
          <Route path={`${VOYAGE_SKIPPER_DETAILS_URL}/page-1`} element={<SkipperAdditionalDetailsForm />} />
          <Route path={`${VOYAGE_SKIPPER_DETAILS_URL}/page-2`} element={<SkipperAdditionalDetailsForm />} />
          <Route path={`${VOYAGE_SKIPPER_DETAILS_URL}/page-3`} element={<SkipperDetailsForm />} />
          <Route path={VOYAGE_SKIPPER_SELECT_URL} element={<SkipperSelectForm />} />
          <Route path={VOYAGE_SUBMITTED_URL} element={<VoyageSubmitted />} />
          <Route path={VOYAGE_TASK_LIST_URL} element={<TaskListContainer />} />
          <Route path={VOYAGE_UPT_RESULTS} element={<UptResultsPageContainer />} />
          <Route
            path="/voyage-plans/:entityId/delete"
            element={(
              <ActionEntity
                notification={{
                  title: 'Success',
                  heading: 'Voyage plan has been successfully cancelled.',
                  entity: 'voyage plan',
                  baseURL: VOYAGE_REPORT_URL,
                  redirectURL: '/voyage-plans',
                  apiHookConfig: [{ status: VOYAGE_STATUSES.PRE_CANCELLED }],
                  action: 'Cancel',
                }}
              />
            )}
          />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
