import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SERVICE_NAME } from '../constants/AppConstants';

const CookieConsent = ({ setState }) => {
  const handleCookies = (choice) => {
    sessionStorage.setItem('useCookies', choice);
    setState((prevState) => {
      return { ...prevState, actionTaken: true, useCookies: choice };
    });
  };

  return (
    <div className="cookies govuk-cookie-banner " data-nosnippet role="region" aria-label={`Cookies on ${SERVICE_NAME}`}>
      <div className="govuk-cookie-banner__message govuk-width-container">

        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            <h2 className="govuk-cookie-banner__heading govuk-heading-m">{`Cookies on ${SERVICE_NAME}`}</h2>

            <div className="govuk-cookie-banner__content">
              <p className="govuk-body">We use some essential cookies to make this service work.</p>
              <p className="govuk-body">We&apos;d like to set additional cookies so we can remember your settings, understand how people use the service and make improvements.</p>
            </div>
          </div>
        </div>

        <div className="govuk-button-group cookies-buttons">
          <button
            value="accept"
            type="submit"
            name="cookies"
            className="govuk-button"
            data-module="govuk-button"
            onClick={() => handleCookies(true)}
          >
            Accept additional cookies
          </button>
          <button
            value="reject"
            type="submit"
            name="cookies"
            className="govuk-button"
            data-module="govuk-button"
            onClick={() => handleCookies(false)}
          >
            Reject additional cookies
          </button>
          <Link to="/privacy-and-cookie-policy" className="govuk-link govuk-link--no-visited-state">View cookies</Link>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;

CookieConsent.propTypes = {
  setState: PropTypes.func,
};
