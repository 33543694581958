import { apiUrl } from './config';

// Account
export const ACTIVATE_ACCOUNT = `${apiUrl}/activate-account`;
export const RESEND_ACTIVATION_LINK = `${apiUrl}/resend-activation-link`;
export const LOGIN_URL = `${apiUrl}/login`;
export const LOGOUT_URL = `${apiUrl}/logout`;
export const PASSWORD_RESET_CONFIRMATION = `${apiUrl}/password-reset-confirmation`;
export const PASSWORD_RESET_LINK = `${apiUrl}/password-reset-link`;
export const REGISTRATION_URL = `${apiUrl}/registration`;
export const USER_URL = `${apiUrl}/user`;

// People & Crafts
export const PEOPLE_URL = `${apiUrl}/user/people`;
export const PLEASURE_CRAFT_URL = `${apiUrl}/user/vessels`;
export const VESSELS_URL = `${apiUrl}/user/vessels`;

// Voyage
export const UPT_CHECK_IN_PART = 'check-in';
export const USER_VOYAGE_REPORT_URL = `${apiUrl}/user/voyagereport`;
export const VOYAGE_REPORT_URL = `${apiUrl}/voyagereport`;
export const VOYAGE_API_PEOPLE_PART = 'people'; // allows you to build up `${VOYAGE_REPORT_URL}/${id}/${VOYAGE_API_PEOPLE_PART}

// Lookups
export const PORTS_URL = `${apiUrl}/ports`;

// API errors
export const DUPLICATE_PEOPLE_ERROR = 'Cannot add duplicate person to voyage report';
export const DUPLICATE_VESSEL_ERROR = 'A vessel with this registration already exists';
