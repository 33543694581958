import { feedbackUrl } from '../constants/config';

const Banner = () => {
  return (
    <div role="region" className="govuk-grid-row">
      <div className="govuk-grid-column-full">
        <div className="govuk-phase-banner">
          <p className="govuk-phase-banner__content">
            <strong className="govuk-tag govuk-phase-banner__content__tag">
              Beta
            </strong>
            <span className="govuk-phase-banner__text" data-testid="banner-text">
              This is a new service – your
              {' '}
              <a className="govuk-link" href={feedbackUrl}>feedback</a>
              {' '}
              will help us to improve it.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
