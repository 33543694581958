/* istanbul ignore file */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import App from './App';
import { ga4MeasurmentId } from './constants/config';

ReactGA.initialize(ga4MeasurmentId);

const root = createRoot(document.getElementById('main'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
